/* Global styles */
*, *::before, *::after {
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* SignIn Component Styles */
.sign-in {
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 350px;
    margin: 50px auto;
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-in form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sign-in input[type="email"],
.sign-in input[type="password"] {
    padding: 12px 15px; /* Adjusted padding to match button padding */
    margin-bottom: 15px;
    border: 1px solid #ddd; /* Ensure border width matches the button if it has a border */
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
}

.sign-in input[type="email"]:focus,
.sign-in input[type="password"]:focus {
    outline: none;
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.25);
}

.sign-in button {
    background-color: #0056b3;
    color: white;
    padding: 12px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    width: 100%; /* Button width set to match input fields */
}

.sign-in button:hover {
    background-color: #003d82;
}

/* Optional: Success message styles */
.success-message {
    color: green;
    font-size: 14px;
    margin-top: 20px;
}
