/* Navbar Component Styles */
nav {
    background-color: #003d82;
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav a {
    text-decoration: none;
    color: white;
    margin: 0 0px;
    font-size: 18px;
}

nav a:first-child {
    margin-left: 0px;
    font-size: 18px;
}

h4 {
    margin: 0;
    font-size: 54px;
    color: white;
    text-align: center;
    flex-grow: 1; 
}
