/* AdminPanel Component Styles */
.admin-panel {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 350px;
    margin: 50px auto;
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-panel h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.admin-panel form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.admin-panel input[type="text"],
.admin-panel input[type="url"],
.admin-panel input[type="file"] { /* Apply styles to text, URL, and file inputs */
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
}

.admin-panel input[type="text"]:focus,
.admin-panel input[type="url"]:focus,
.admin-panel input[type="file"]:focus { /* Apply focus styles to text, URL, and file inputs */
    outline: none;
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.25);
}

.admin-panel button {
    background-color: #0056b3;
    color: white;
    padding: 12px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    width: 100%;
}

.admin-panel button:hover {
    background-color: #003d82;
}
