/* MainContent Component Styles */
h1 {
    font-size: 24px;
    color: #333;
    margin: 20px auto;
    text-align: center;
}

/* Search bar styles similar to the sign-in screen */
.search-bar {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 350px;
    margin: 20px auto;
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-bar form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.search-bar input[type="text"] {
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
}

.search-bar input[type="text"]:focus {
    outline: none;
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.25);
}

.search-bar button {
    background-color: #0056b3;
    color: white;
    padding: 12px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    width: 100%;
}

.search-bar button:hover {
    background-color: #003d82;
}

/* Center product results */
.search-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    max-width: 350px; /* Adjust max-width for smaller screens */
    text-align: center;
}

.search-results div {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%; /* Make product results full width on smaller screens */
}

.search-results h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
}

.search-results p {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
}

.search-results a {
    text-decoration: none;
    color: #0056b3;
    display: block;
}

.search-results a:hover {
    text-decoration: underline;
}

/* Limit image size to a maximum of 500x500px */
.search-results img {
    max-width: 100%; /* Make images responsive */
    max-height: 500px;
    width: auto;
}